@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "Inter";
  src: url("assets/font/Inter-Bold.eot");
  src: url("assets/font/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Inter-Bold.woff2") format("woff2"),
    url("assets/font/Inter-Bold.woff") format("woff"),
    url("assets/font/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/font/Inter-SemiBold.eot");
  src: url("assets/font/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Inter-SemiBold.woff2") format("woff2"),
    url("assets/font/Inter-SemiBold.woff") format("woff"),
    url("assets/font/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/font/Inter-Medium.eot");
  src: url("assets/font/Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Inter-Medium.woff2") format("woff2"),
    url("assets/font/Inter-Medium.woff") format("woff"),
    url("assets/font/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/font/Inter-Regular.eot");
  src: url("assets/font/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/font/Inter-Regular.woff2") format("woff2"),
    url("assets/font/Inter-Regular.woff") format("woff"),
    url("assets/font/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

body * {
  font-family: "Inter", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  margin: -16px;
  padding: 16px;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.ReactCrop__child-wrapper {
  height: 100%;
}
